import { Rule } from 'flows/constants'
import { Action, Flow } from 'shared/constants'
import { useRules } from './useRules'

export const useSelectedRules = (flow: Flow, selectedConnection: string) => {
  const rules = useRules(flow)

  if (rules.isLoading || !rules.isSuccess) {
    return {
      rules: {} as Record<Action, Rule[]>,
    }
  }

  const filteredRules = { ...rules.data }

  Object.keys(filteredRules).forEach((key) => {
    filteredRules[key as Action] = filteredRules[key as Action].filter((r) => {
      const result = r.conditions.filter(
        (condition) =>
          condition.name === 'payment_method' &&
          condition.value === selectedConnection
      )
      return result.length
    })
  })

  return {
    rules: filteredRules,
  }
}
