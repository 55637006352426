import { useContext } from 'react'
import FilterContext from 'home/contexts/FilterContext'
import { formatAmount } from 'home/helpers/format'
import currencyFormat from 'shared/helpers/currency-format'

export const useConnectionAmount = (value: number) => {
  const { filters } = useContext(FilterContext)

  const currencyOptions: Intl.NumberFormatOptions = {
    currency: filters.currency,
    currencyDisplay: 'narrowSymbol',
  }

  return filters.filterBy === 'volume'
    ? currencyFormat(value, currencyOptions)
    : formatAmount(value, 'decimal')
}
