import { DropResult } from '@hello-pangea/dnd'
import { OutcomeInstrument } from 'flows/constants'
import {
  Outcome,
  OutcomeFormValues,
  OutcomeResult,
  OutcomeTransformation,
  OutcomeTransformationName,
  TransactionActionOutcome,
} from 'flows/constants/outcome'

export const getInstrumentName = (instrument: OutcomeInstrument) =>
  ({
    [OutcomeInstrument.PAN]: 'PAN',
    [OutcomeInstrument.NETWORK_TOKEN]: 'Network Token',
  })[instrument]

export const getTransformationName = (transformation: OutcomeTransformation) =>
  ({
    [OutcomeTransformationName.FORCE_MIT]: 'MIT',
    [OutcomeTransformationName.USE_ADDITIONAL_SCHEME]:
      'Force co-badged network',
  })[transformation.name]

export const getTransformationNames = (
  transformations: OutcomeTransformation[]
) =>
  !!transformations.length
    ? transformations
        .map((transformation) => getTransformationName(transformation))
        .join(', ')
    : ''

export const getInstrumentDescription = (instrument: OutcomeInstrument) =>
  ({
    [OutcomeInstrument.PAN]: {
      title: 'Card number (PAN)',
      text: 'The original card number (PAN) will be sent.',
    },
    [OutcomeInstrument.NETWORK_TOKEN]: {
      title: 'Network token',
      text: 'A network token will be sent if available, including a cryptogram if required.',
    },
  })[instrument]

export const getInstrumentOptions = (
  selectedConnection?: TransactionActionOutcome
) =>
  (selectedConnection?.instruments ?? []).map((instrument) => ({
    value: instrument,
    ...getInstrumentDescription(instrument),
  }))

const getTransformationDescription = (
  transformation: OutcomeTransformationName
) =>
  ({
    [transformation]: transformation,
    [OutcomeTransformationName.FORCE_MIT]: {
      title: 'Force MIT',
      text: 'Attempt a Merchant Initiated transaction (MIT) without the CVV even if the transaction was marked as customer-present (CIT).',
    },
    [OutcomeTransformationName.USE_ADDITIONAL_SCHEME]: {
      title: 'Force co-badged network',
      text: "Use a card's alternative - often local debit rails - card scheme. For example, use EFTPOS instead of Visa.",
    },
  })[transformation]

export const getTransformationOptions = (
  selectedConnection?: TransactionActionOutcome
) =>
  (selectedConnection?.transformations ?? []).map((transformation) => ({
    value: transformation.name,
    ...getTransformationDescription(transformation.name),
  }))

export const preparePayload = ({
  paymentServiceId,
  instrument,
  transformationsForceMit,
  transformationsUseAdditionalScheme,
}: OutcomeFormValues): OutcomeResult => {
  const transformations: OutcomeTransformation[] = [
    ...(transformationsForceMit
      ? [{ name: OutcomeTransformationName.FORCE_MIT }]
      : []),
    ...(transformationsUseAdditionalScheme
      ? [{ name: OutcomeTransformationName.USE_ADDITIONAL_SCHEME }]
      : []),
  ]

  return {
    paymentServiceId,
    instrument,
    transformations,
  }
}

export const reorderConnections = (
  event: DropResult,
  connections: OutcomeResult[]
) => {
  if (!event.destination) {
    return connections
  }

  const reorderedConnections = [...connections]

  const [draggedItem] = reorderedConnections.splice(event.source.index, 1)
  reorderedConnections.splice(event.destination.index, 0, draggedItem)

  return reorderedConnections
}

export const removeConnectionByIndex = (
  connectionIndex: number,
  connections: OutcomeResult[]
) => {
  const updatedConnections = [...connections]
  updatedConnections.splice(connectionIndex, 1)

  return updatedConnections
}

export const isTransactionActionOutcome = (
  outcome: Outcome
): outcome is TransactionActionOutcome => {
  return outcome.actionType === 'transaction-route'
}

export const getCardTransactionOutcomesIds = (
  outcomes: Outcome[] | undefined
) =>
  Array.isArray(outcomes) && outcomes.length > 0
    ? outcomes.filter(isTransactionActionOutcome).map((outcome) => outcome.id)
    : []

export const formatOutcomes = (
  outcomes: TransactionActionOutcome[],
  connections: OutcomeResult[] = []
) => {
  const outcomesIds = outcomes.map((outcome) => outcome.id)

  const connectionsInUse: TransactionActionOutcome[] = connections.reduce(
    (acc: TransactionActionOutcome[], curr: OutcomeResult) => {
      if (outcomesIds.includes(curr.paymentServiceId)) {
        const outcome = outcomes.find(
          (outcome) => outcome.id === curr.paymentServiceId
        )

        if (outcome && outcome.actionType === 'transaction-route') {
          acc = [
            ...acc,
            {
              ...outcome,
              instruments: [curr.instrument],
              transformations: curr.transformations,
            },
          ]
        }
      }
      return acc
    },
    []
  )

  return connectionsInUse
}
