interface ConnectionProps {
  id: string
  group: string
  definitionId: string
}

const getMethodByDefinitionId = (definitionId: string) =>
  ({
    [definitionId]: { method: definitionId },
    apple: { method: 'applepay' },
    google: { method: ['googlepay', 'googlepay_pan_only'] },
    'click-to-pay': { method: 'click-to-pay' },
  })[definitionId]

export const getTransactionsFilterByConnection = ({
  id,
  group,
  definitionId,
}: ConnectionProps): Record<string, unknown> => {
  if (group === 'payment-service') {
    return {
      paymentServiceId: id,
    }
  }

  if (group === 'digital-wallet') {
    return getMethodByDefinitionId(definitionId)
  }

  return {}
}
