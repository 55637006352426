import { isSameDay } from 'date-fns'
import dayjs from 'dayjs'
import { PercentageBadge } from 'home/components/PercentageBadge'
import { ConfigProps } from 'home/constants/config'
import { Tab } from 'home/hooks/use-health-dashboard'
import { currencies } from 'shared/helpers/currency'
import { dateFormat } from 'shared/helpers/date-format'
import { getCurrentLocale } from 'shared/helpers/locale'

export const formatAmount = (
  value: number | null,
  numberFormat?: ConfigProps['numberFormat']
): string => {
  if (
    typeof value !== 'number' ||
    isNaN(value) ||
    (value === 0 && numberFormat === 'percent')
  ) {
    return '-'
  }

  const options: Intl.NumberFormatOptions = {
    ...(numberFormat === 'decimal' && {
      style: 'decimal',
      minimumSignificantDigits: 1,
    }),
    ...((numberFormat === 'decimalCompact' || !numberFormat) && {
      style: 'decimal',
      minimumSignificantDigits: 1,
      maximumSignificantDigits: 3,
      notation: 'compact',
    }),
    ...(numberFormat === 'percent' && { style: 'percent' }),
  }

  return new Intl.NumberFormat(getCurrentLocale(), options).format(
    numberFormat === 'decimal' ? value : value / 100
  )
}

export const YAxisFormatter = (
  value: number,
  numberFormat?: ConfigProps['numberFormat']
) => (value <= 1 ? String(value) : formatAmount(value, numberFormat))

export const formatLabel = (dateTime: string, length: number) => {
  const sameDay = isSameDay(dateTime, new Date().toISOString())

  if (length >= 30) {
    if (sameDay) {
      return (
        dateFormat(dateTime, getCurrentLocale(), {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }) + ' (today)'
      )
    }

    const [date, time] = dateFormat(dateTime, getCurrentLocale(), {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      hour12: false,
    })
      .replace(',', '')
      .split(' ')

    return `${time} ${date}`
  }

  const suffix = sameDay ? ' - today' : ' - yesterday'

  return (
    dateFormat(dateTime, getCurrentLocale(), {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }) + suffix
  )
}

export const formatCurrencies = (statusCurrencies: string[] | undefined) => {
  if (!statusCurrencies || !statusCurrencies?.length) {
    return []
  }

  return currencies
    .filter((currency) => statusCurrencies.includes(currency.code))
    .map((currency) => ({
      label: `${currency.code} - ${currency.currency}`,
      value: currency.code,
    }))
}

export const formatTimestampLabel = (timestamp?: string) => {
  if (!timestamp) {
    return { date: null, time: null }
  }

  const differenceInDays = dayjs(new Date()).diff(timestamp, 'day')
  const date = new Date(Date.parse(timestamp)).toLocaleDateString(
    getCurrentLocale()
  )

  const time = new Date(Date.parse(timestamp)).toLocaleTimeString(
    getCurrentLocale(),
    {
      timeStyle: 'short',
    }
  )

  if (differenceInDays === 0) {
    return { date: 'today', time }
  }

  if (differenceInDays === 1) {
    return { date: 'yesterday', time }
  }

  return { date, time }
}

export const getPercentageTrend = (tab: Tab, value?: number | null) => {
  if (typeof value !== 'number') {
    return null
  }

  if (value === 0) {
    return <PercentageBadge percentage={0} variant="neutral" />
  }

  return (
    <PercentageBadge
      percentage={value}
      variant={
        tab === 'refunds'
          ? value < 0
            ? 'positive'
            : 'negative'
          : value < 0
            ? 'negative'
            : 'positive'
      }
      direction={value < 0 ? 'down' : 'up'}
    />
  )
}
