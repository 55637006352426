import { OutcomeResult, Rule, RuleOutcomeType } from 'flows/constants'
import { getCardTransactionOutcomesIds } from 'flows/utils/cardOutcome'
import { Action, Flow } from 'shared/constants'
import { useOutcomes } from './useOutcomes'
import { useRule } from './useRule'
import { useRuleConfig } from './useRuleConfig'

export const validateConditions = (rule: Rule) => {
  return rule.conditions.map((condition) => {
    if (condition.name == 'metadata') {
      if (!Array.isArray(condition.value.value)) {
        condition.value.value = [condition.value.value]
      }
    }
    return condition
  })
}

const isOutcomeResultV2 = (
  result: string[] | OutcomeResult[]
): result is OutcomeResult[] =>
  result.length > 0 &&
  typeof result[0] === 'object' &&
  'paymentServiceId' in result[0]

export const validateDeletedConnections = (
  rule: Rule,
  outcomesIds: string[]
) => {
  rule.conditions = validateConditions(rule)

  if (
    rule.outcome.type === RuleOutcomeType.CARD_ROUTING &&
    isOutcomeResultV2(rule.outcome.result)
  ) {
    rule.outcome.result = rule.outcome.result.filter((result) =>
      outcomesIds.includes(result.paymentServiceId)
    )
  }
  return rule
}

export const useEditableRule = (
  merchantAccountId: string,
  flow: Flow,
  action: Action,
  id: string,
  searchParams: URLSearchParams = new URLSearchParams()
  // eslint-disable-next-line max-params
) => {
  const ruleQuery = useRule(flow, action, id)
  const ruleConfig = useRuleConfig(flow, action)
  const outcomesQuery = useOutcomes({
    merchantAccountId,
    flow,
    action,
    outcome: ruleConfig?.data?.outcome,
    queryParams: searchParams,
  })

  if (ruleQuery.isLoading || ruleConfig.isLoading || outcomesQuery.isLoading) {
    return {
      isLoading: true,
      isSuccess: false as const,
    }
  }

  if (
    !ruleQuery.isSuccess ||
    !ruleConfig.isSuccess ||
    !outcomesQuery.isSuccess
  ) {
    return {
      isLoading: false,
      isSuccess: false as const,
    }
  }

  return {
    isLoading: false as const,
    isSuccess: true as const,
    rule: validateDeletedConnections(
      ruleQuery.data,
      getCardTransactionOutcomesIds(outcomesQuery.data.items)
    ),
    ruleConfig: ruleConfig.data,
    outcomes: outcomesQuery.data.items,
  }
}
