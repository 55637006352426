import { Box, Divider, Heading, Stack } from '@gr4vy/poutine-react'
import { useContext } from 'react'
import FilterContext from 'home/contexts/FilterContext'
import { useHealthDashboardConnections } from 'home/hooks/use-health-dashboard-connections'
import { useTransactions } from 'home/hooks/use-transactions'
import {
  AccessLevel,
  Resource,
  useResourcePermissions,
} from 'shared/permissions'
import { TransactionFilters } from 'shared/services/transactions'
import TransactionTable from 'transactions/components/TransactionTable'
import ConnectionsTable from '../ConnectionsTable/ConnectionsTable'

export const Tables = () => {
  const { filters, setFilters } = useContext(FilterContext)
  const [canReadConnections, canReadTransactions] = useResourcePermissions(
    [Resource.connections, Resource.transactions],
    AccessLevel.read
  )
  const connections = useHealthDashboardConnections({
    filters,
    enabled: canReadConnections,
  })
  const transactions = useTransactions({
    filters,
    enabled: canReadTransactions,
  })

  const onFilter = (newFilters: TransactionFilters) => {
    setFilters({ ...filters, ...newFilters })
  }

  return canReadConnections || canReadTransactions ? (
    <Stack gap={56}>
      {canReadConnections && (
        <Stack gap={16}>
          <Heading level={4}>Connections</Heading>
          <Box>
            <Divider width="md" borderColor="gray40" />
            <ConnectionsTable page={connections} />
          </Box>
        </Stack>
      )}
      {canReadTransactions && (
        <Stack gap={16}>
          <Heading level={4}>Authorized Transactions</Heading>
          <Box>
            <Divider width="md" borderColor="gray40" />
            <TransactionTable
              page={transactions}
              pagination={{ filters, onFilter, limits: [10, 20, 50, 100] }}
              disableColumnFilters
            />
          </Box>
        </Stack>
      )}
    </Stack>
  ) : null
}
