export const ANTI_FRAUD_SERVICES = 'anti-fraud-services'
export const ANTI_FRAUD_SERVICE_DEFINITIONS = 'anti-fraud-service-definitions'
export const APPLE_PAY_CERTIFICATES = 'apple-pay-certificates'
export const CONNECTIONS = 'connections'
export const CONNECTION_DEFINITIONS = 'connection-definitions'
export const DIGITAL_WALLETS = 'digital-wallets'
export const DIGITAL_WALLET = 'digital-wallet'
export const PAYMENT_SERVICES = 'payment-services'
export const PAYMENT_SERVICE = 'payment-service'
export const PAYMENT_SERVICE_DEFINITION = 'payment-service-definition'
export const PAYMENT_SERVICE_DEFINITIONS = 'payment-service-definitions'
export const VERIFY_CREDENTIALS = 'verify-credentials'
export const GIFT_CARD_SERVICE_DEFINITIONS = 'gift-card-service-definitions'
export const GIFT_CARD_SERVICES = 'gift-card-services'
export const CONNECTIONS_TRANSACTIONS = 'connections-transactions'
export const CONNECTIONS_VOLUME = 'connections-volume'
