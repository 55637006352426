import { Description } from '@gr4vy/poutine-react'

interface AmountProps {
  value: string
}

export const Amount = ({ value }: AmountProps) => {
  return (
    <Description align="flex-end">
      <Description.Text
        style={{
          fontVariantNumeric: 'tabular-nums',
        }}
      >
        {value}
      </Description.Text>
    </Description>
  )
}
