import { Description } from '@gr4vy/poutine-react'

export interface NumericDescriptionProps {
  children?: string | number | null
}

export const NumericDescription = ({ children }: NumericDescriptionProps) => {
  return (
    <Description align="flex-end">
      <Description.Text
        style={{
          fontVariantNumeric: 'tabular-nums',
        }}
      >
        {['number', 'string'].includes(typeof children)
          ? String(children)
          : '-'}
      </Description.Text>
    </Description>
  )
}
