import {
  Flex,
  Box,
  Icon,
  Dropdown,
  Description,
  Text,
  Stack,
  Skeleton,
  Badge,
} from '@gr4vy/poutine-react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pathTo as pathToConnections } from 'connections'
import { HomeFilters } from 'home/constants/filter'
import { formatAmount, getPercentageTrend } from 'home/helpers/format'
import { getTransactionsFilterByConnection } from 'home/helpers/transaction'
import { useConnectionAmount } from 'home/hooks/use-connection-amount'
import { useConnectionsInfo } from 'home/hooks/use-connections-info'
import {
  HealthDashboardConnection,
  HealthDashboardConnectionVolume,
} from 'home/services/health-dashboard'
import { ClientPagination } from 'shared/components/ClientPagination'
import {
  ColumnDef,
  DataTable,
  DataTableProps,
} from 'shared/components/DataTable'
import { useClientFiltering, useFilters } from 'shared/hooks'
import { pathTo as pathToTransactions } from 'shared/paths/transactions'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'
import { QueryResult } from 'shared/services/client'
import { Amount } from './Amount'

export type Connection =
  | HealthDashboardConnectionVolume
  | HealthDashboardConnection

export type ConnectionsTableProps = Pick<
  DataTableProps<Connection>,
  'pagination'
> & {
  page: Pick<QueryResult<Connection>, 'data' | 'isLoading'>
}

export const connectionsColumns: Array<ColumnDef<Connection>> = [
  {
    id: 'name',
    accessorKey: 'paymentServiceDisplayName',
    header: 'Connection',
    size: 112,
    cell: function Name({ row }) {
      const { connectionsInfo, isLoading } = useConnectionsInfo()
      const connectionIcon =
        !!connectionsInfo && !isLoading ? (
          connectionsInfo[row.original.summary.paymentServiceDefinitionId]
            .iconUrl
        ) : (
          <ConnectionSkeletonIcon />
        )
      const {
        paymentServiceDisplayName,
        paymentServiceIsDeleted,
        paymentServiceIsActive,
      } = row.original.summary

      return (
        <Flex gap={8} alignItems="center" width="full">
          <Box width="full" minWidth={0}>
            <Description>
              <Description.Icon src={connectionIcon} size={24} />
              <Description.Text>{paymentServiceDisplayName}</Description.Text>
            </Description>
          </Box>
          {paymentServiceIsDeleted && <Badge variant="subtle">Deleted</Badge>}
          {!paymentServiceIsDeleted && !paymentServiceIsActive && (
            <Badge variant="subtle">Inactive</Badge>
          )}
        </Flex>
      )
    },
  },
  {
    id: 'authRate',
    size: 50,
    header: () => <Text textAlign="right">Auth rate</Text>,
    cell: ({ row }) => {
      const transactionsAuthorizedRateDelta =
        row.original.summary.transactionsAuthorizedRateDelta

      return (
        <Flex alignItems="center" justifyContent="flex-end">
          {getPercentageTrend('authRate', transactionsAuthorizedRateDelta)}
          <Flex gap={4} alignItems="center" justifyContent="space-around">
            <Text>
              {formatAmount(
                row.original.summary.transactionsAuthorizedRate,
                'percent'
              )}
            </Text>
          </Flex>
        </Flex>
      )
    },
  },
  {
    id: 'data',
    header: ({ table }) => {
      const firstRow = table.getRowModel().rows[0]
      const type = firstRow?.original.type

      return (
        <Text textAlign="right">
          {type?.includes('volume') ? 'Volume' : 'Transactions'}
        </Text>
      )
    },
    size: 66,
    cell: function Authorized({ row }) {
      const formattedValue = useConnectionAmount(
        row.original.summary.transactionsAuthorized
      )

      return <Amount value={formattedValue} />
    },
  },
  {
    id: 'declined',
    header: () => <Text textAlign="right">Declined</Text>,
    accessorKey: 'transactionsDeclined',
    size: 66,
    cell: function Declined({ row }) {
      const formattedValue = useConnectionAmount(
        row.original.summary.transactionsDeclined
      )
      return <Amount value={formattedValue} />
    },
  },
  {
    id: 'recovered',
    header: () => <Text textAlign="right">Recovered</Text>,
    accessorKey: 'transactionsRecovered',
    size: 66,
    cell: function Recovered({ row }) {
      const formattedValue = useConnectionAmount(
        row.original.summary.transactionsRecovered
      )

      return <Amount value={formattedValue} />
    },
  },
  {
    id: 'actions',
    accessorKey: 'id',
    size: 0,
    header: '',
    cell: function Actions({ row }) {
      const { merchantAccountId } = useParams<{
        merchantAccountId: string
      }>()
      const canEditConnections = useResourcePermission(
        Resource.connections,
        AccessLevel.write
      )
      const { connectionsInfo } = useConnectionsInfo()
      const { paymentServiceId, paymentServiceDefinitionId } =
        row.original.summary

      const connection = {
        id: paymentServiceId,
        group: connectionsInfo?.[paymentServiceDefinitionId].group,
        definitionId: paymentServiceDefinitionId,
      }

      return merchantAccountId ? (
        <Box textAlign="center">
          <Dropdown>
            <Dropdown.Trigger asChild>
              <Dropdown.Button variant="tertiary" size="small">
                <Icon name="more-horizontal" />
              </Dropdown.Button>
            </Dropdown.Trigger>
            <Dropdown.Content align="end">
              {canEditConnections && connection.group && (
                <a
                  href={pathToConnections.editConnectionSelector(
                    merchantAccountId,
                    connection.group,
                    connection.id,
                    connection.definitionId
                  )}
                >
                  <Dropdown.Item>Edit connection</Dropdown.Item>
                </a>
              )}
              {connection.group &&
                ['payment-service', 'digital-wallet'].includes(
                  connection.group
                ) && (
                  <a
                    href={pathToTransactions.transactions(
                      merchantAccountId,
                      getTransactionsFilterByConnection({
                        id: connection.id,
                        group: connection.group,
                        definitionId: connection.definitionId,
                      })
                    )}
                  >
                    <Dropdown.Item>View transactions</Dropdown.Item>
                  </a>
                )}
            </Dropdown.Content>
          </Dropdown>
        </Box>
      ) : null
    },
  },
]

const ConnectionSkeletonIcon = () => (
  <Skeleton width={24}>
    <Skeleton.Box width={24} height={24} borderRadius="rounded" />
  </Skeleton>
)

const ConnectionsTable = ({ page, pagination }: ConnectionsTableProps) => {
  const [filters] = useFilters<HomeFilters>()
  const { data, isLoading } = page

  const { pagedData, setPage, ...rest } = useClientFiltering(
    data?.items || [],
    {
      limit: 10,
    }
  )

  useEffect(() => {
    if (filters?.currency || filters?.filterBy || filters?.period) {
      setPage(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.currency, filters?.filterBy, filters?.period])

  return (
    <Stack gap={32} width="full">
      <DataTable
        data={{ items: pagedData }}
        loading={isLoading}
        columns={connectionsColumns}
        pagination={pagination}
      >
        <DataTable.Empty>
          <Flex justifyContent="center" width="readable" paddingX={80}>
            <Text variant="reg4" textAlign="center">
              Once transactions start coming through you will see how each
              connection is performing.
            </Text>
          </Flex>
        </DataTable.Empty>
        <DataTable.Loading>Loading...</DataTable.Loading>
      </DataTable>
      {!!data && data.items.length > 10 && (
        <ClientPagination {...rest} limits={[10, 20, 50, 100]} />
      )}
    </Stack>
  )
}

export default ConnectionsTable
