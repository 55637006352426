import { useState } from 'react'

export interface ClientFilter {
  limit: number
  search?: string
  page: number
}

export const useClientFiltering = <T>(data: T[], params = { limit: 5 }) => {
  const [filter, setFilter] = useState<ClientFilter>({ ...params, page: 0 })

  let pagedData = data
  let totalItems = data.length

  if (filter.search) {
    const [key, value] = filter.search.split(':')
    pagedData =
      key && value
        ? pagedData.filter((item) =>
            (item[key as keyof T] as string).includes(value)
          )
        : pagedData
    totalItems = pagedData.length
  }

  const totalPages = Math.max(Math.ceil(totalItems / filter.limit) - 1, 0)

  pagedData = pagedData.slice(
    filter.page * filter.limit,
    (filter.page + 1) * filter.limit
  )

  const setLimit = (limit: number) => setFilter({ ...filter, limit, page: 0 })
  const previousPage = () => setFilter({ ...filter, page: filter.page - 1 })
  const nextPage = () => setFilter({ ...filter, page: filter.page + 1 })
  const setSearch = (search: string) =>
    setFilter({ ...filter, page: 0, search })
  const setPage = (page: number) => setFilter({ ...filter, page })

  return {
    pagedData,
    totalPages,
    filter,
    setLimit,
    previousPage,
    nextPage,
    setSearch,
    setPage,
  }
}
