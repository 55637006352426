import { Button, Flex, Grid, GridItem, Stack, Text } from '@gr4vy/poutine-react'
import { UseMutationResult } from '@tanstack/react-query'
import { Form, Input } from 'antd'
import { useNavigate } from 'react-router-dom'
import { MerchantPagesLayout } from 'admin/components/MerchantsPageLayout'
import { pathTo } from 'admin/paths'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  MerchantAccount,
  UpdateMerchantAccount,
} from 'shared/services/merchant-accounts'
import { OverCaptureExample } from './OverCaptureExample'

const getBreadcrumbs = (displayName: string) => [
  { title: 'Merchants', url: pathTo.merchants },
  { title: displayName },
]

interface EditMerchantAccountOverCapturePageProps {
  title: string
  merchantAccount: MerchantAccount
  loading: boolean
  update: UseMutationResult<
    MerchantAccount,
    any,
    UpdateMerchantAccount,
    unknown
  >
}

const EditMerchantAccountOverCapturePage = ({
  title,
  merchantAccount,
  loading,
  update,
}: EditMerchantAccountOverCapturePageProps) => {
  const [form] = Form.useForm<UpdateMerchantAccount>()
  const overCaptureAmount = Form.useWatch('overCaptureAmount', form)
  const overCapturePercentage = Form.useWatch('overCapturePercentage', form)
  const navigate = useNavigate()

  const onFinish = async (values: UpdateMerchantAccount) => {
    await form.validateFields()
    update.mutate({
      ...values,
      id: merchantAccount.id,
      overCaptureAmount: Number(values.overCaptureAmount),
      overCapturePercentage: Number(values.overCapturePercentage),
    })
  }

  return (
    <MerchantPagesLayout
      title={title}
      breadcrumbs={getBreadcrumbs(merchantAccount.displayName)}
      isLoading={loading}
    >
      <Grid>
        <GridItem gridColumn="span 6">
          <Stack gap={24} marginBottom={24}>
            <Text>
              Set a limit to the maximum amount that can be captured over the
              authorized amount.
            </Text>
            <Text>
              Over-captured transactions above the set limits will be declined.
              If neither of the limits are set then the over-capture is
              disabled. Limits are independent of the currency used.
            </Text>
          </Stack>
        </GridItem>
      </Grid>
      <Form
        name="update-over-capture"
        form={form}
        initialValues={{
          id: merchantAccount.id,
          overCaptureAmount: merchantAccount.overCaptureAmount,
          overCapturePercentage: merchantAccount.overCapturePercentage,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Stack gap={24}>
          <Grid>
            <GridItem gridColumn="span 4">
              <Stack gap={24}>
                <FormItem name="id" hidden>
                  <Input type="hidden" />
                </FormItem>
                <FormItem
                  required={false}
                  name="overCaptureAmount"
                  label={
                    <Label>Limit in minor units (e.g. cents, pence, Yen)</Label>
                  }
                  rules={[
                    {
                      type: 'number',
                      message: 'Must contain digits only',
                      transform(value) {
                        return value && Number(value)
                      },
                    },
                  ]}
                >
                  <Input />
                </FormItem>
                <FormItem
                  required={false}
                  name="overCapturePercentage"
                  label={<Label>Limit as a percentage</Label>}
                  rules={[
                    {
                      type: 'number',
                      message: 'Must contain digits only',
                      transform(value) {
                        return value && Number(value)
                      },
                    },
                  ]}
                >
                  <Input />
                </FormItem>
              </Stack>
            </GridItem>
          </Grid>
          <OverCaptureExample
            amount={
              overCaptureAmount === null ? null : Number(overCaptureAmount)
            }
            percentage={
              overCapturePercentage === null
                ? null
                : Number(overCapturePercentage)
            }
          />
          <Flex gap={16}>
            <Button
              form="update-over-capture"
              loading={update.isPending}
              variant="primary"
            >
              Update
            </Button>
            <Button
              variant="tertiary"
              disabled={update.isPending}
              onClick={() => navigate(pathTo.merchants)}
            >
              Cancel
            </Button>
          </Flex>
        </Stack>
      </Form>
    </MerchantPagesLayout>
  )
}

export default EditMerchantAccountOverCapturePage
