export const ArrowDownIcon = () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Arrow down"
  >
    <path d="M6 8.5L6.99382e-07 0.499999L12 0.5L6 8.5Z" fill="currentColor" />
  </svg>
)
