import { routes as buyerRoutes } from 'buyers'
import { routes as connectionRoutes } from 'connections'
import { NotFoundPage } from 'errors/pages'
import { routes as flowRoutes } from 'flows'
import { routes as giftCardRoutes } from 'gift-cards'
import { routes as homeRoutes } from 'home'
import { routes as paymentMethodRoutes } from 'payment-methods'
import { routes as refundRoutes } from 'refunds'
import { routes as reportsRoutes } from 'reports'
import { Route } from 'shared/constants/routes'
import { routes as transactionRoutes } from 'transactions'

export const routes: Route[] = [
  ...homeRoutes,
  ...transactionRoutes,
  ...connectionRoutes,
  ...flowRoutes,
  ...buyerRoutes,
  ...refundRoutes,
  ...reportsRoutes,
  ...paymentMethodRoutes,
  ...giftCardRoutes,
  {
    title: 'Page Not Found',
    key: '404',
    path: '*',
    component: NotFoundPage,
  },
]
