import { useConnectionDefinitions } from 'connections/hooks/use-connection-definitions'

type ConnectionInfoProps = Record<
  string,
  {
    iconUrl: string
    group: string
  }
>

export const useConnectionsInfo = () => {
  const { definitions, isLoading } = useConnectionDefinitions()
  const connectionsInfo = definitions?.reduce(
    (acc: ConnectionInfoProps, definition) => {
      return {
        ...acc,
        [definition.id]: {
          iconUrl: definition.iconUrl,
          group: String(definition.group),
        },
      }
    },
    {} as ConnectionInfoProps
  )

  return { connectionsInfo, isLoading }
}
